
import {
	BrowserRouter as Router,
	Route,
	Routes,
x
} from "react-router-dom";
import Dashboard from "./component/Publishers/Dashboard";
import Report from "./component/Buyers/Report";
import Login from "./component/Login";
import Target from "./component/Buyers/Target";
import Profile from "./component/Profile";
import Numbers from "./component/Publishers/Numbers";
import Campaigns from "./component/Publishers/Campaigns";

import "./App.css";
// theme
import "primereact/resources/themes/lara-light-blue/theme.css";

import PublisherReport from "./component/Publishers/Report";
import { useState } from "react";

const App = () => {
	const [userData,setuserdata] = useState(JSON.parse(localStorage.getItem('userData'))); 

		// const userData = JSON.parse(localStorage.getItem('userData'));
		// console.log("userData in app component",userData);
	const iscallapp = (data) =>{
		setuserdata(data)
	}
	
	
	return (
		<Router>
		<div className="layout-wrapper layout-content-navbar">
			<div className="layout-container">
			
				<Routes>							
					<Route path="/" element={<Login iscallapp={iscallapp}/>} />
					<Route path="/dashboard" element={<Dashboard />} />		
					<Route path="/profile" element={<Profile />} />			
					<Route path="/target" element={<Target />} />
					<Route  path="/report" element={userData && userData.role == 4 ? <Report  /> : <PublisherReport/>}/>	
					<Route path="/numbers" element={<Numbers />} />
					<Route path="/campaigns" element={<Campaigns />} />
				</Routes>
			</div>
		</div>
	</Router>
	);
};

export default App;
