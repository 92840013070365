// authSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loggedin: false,
};

const authSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    loginSuccess: (state) => {
      state.loggedin = true;
    },
  },
});

export const { loginSuccess } = authSlice.actions;

export default authSlice.reducer;
