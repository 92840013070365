import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../logo/logo.png';


const Sidebar = () => {
  const [activeMenuItem, setActiveMenuItem] = useState(""); 
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    setUserData(userData);
  }, []);

  const handleMenuItemClick = (menuItemId) => {
    setActiveMenuItem(menuItemId);
  };

  const showhidemenu = () => {
    let _userAccount = document.getElementById('htmltag');
    _userAccount.classList.remove('layout-menu-expanded');
  };

  return (
    <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme h-100">
      <div className="app-brand demo">
        <a className="app-brand-link">
          <div className='d-flex justify-content-center align-items-center'>
            <img src={Logo} alt="Logo" height="40px" width="180px" className='Logoimg'/>
          </div>
        </a>
        <a onClick={showhidemenu} className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
          <i className="bx bx-chevron-left bx-sm align-middle"></i>
        </a>
      </div>

      <div className='h-10'>
        <ul className="menu-inner">
          <li className="menu-item" class={`menu-item ${activeMenuItem === "dashboard" ? "active" : ""}`} onClick={() => handleMenuItemClick("dashboard")}>
            <Link to="/dashboard">
              <div className="menu-link">
                <i className="menu-icon tf-icons bx bx-home-circle"></i>
                <div className='text-link' data-i18n="Analytics">Dashboard</div>
              </div>
            </Link>
          </li>

          <li className="menu-item" class={`menu-item ${activeMenuItem === "report" ? "active" : ""}`} onClick={() => handleMenuItemClick("report")}>
            <Link to="/report">
              <div className="menu-link">
                <i className="bi bi-graph-up-arrow  me-3"></i>
                <div className='text-link' data-i18n="Layouts">Report</div>
              </div>
            </Link>          
          </li>

          {userData && userData.role === 3 && (
            <>
              <li className="menu-item" class={`menu-item ${activeMenuItem === "numbers" ? "active" : ""}`} onClick={() => handleMenuItemClick("numbers")}>
                <Link to="/numbers">
                  <div className="menu-link">
                    <i className="bi bi-telephone-fill me-3"></i>
                    <div>Numbers</div>
                  </div>
                </Link>
              </li>

              <li className="menu-item" class={`menu-item ${activeMenuItem === "campaigns" ? "active" : ""}`} onClick={() => handleMenuItemClick("campaigns")}>
                <Link to="/campaigns">
                  <div className="menu-link">
                    <i className="bi bi-megaphone-fill me-3"></i>
                    <div className='text-link' data-i18n="Layouts">Campaigns</div>
                  </div>
                </Link>
              </li>  
            </>
          )}

{userData && userData.role === 4 && (<li className="menu-item" class={`menu-item ${activeMenuItem === "target" ? "active" : ""}`} onClick={() => handleMenuItemClick("target")}>
            <Link to="/target">
              <div className="menu-link">
              <i className="bi bi-bullseye me-3"></i> 
                <div className='text-link' data-i18n="Layouts">Target</div>
              </div>
            </Link>          
          </li>)}
        </ul>
      </div>
    </aside>
  );
}

export default Sidebar;
