import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Nav from './Nav';
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useEffect } from 'react';

const Profile = () => {
  const [company, setCompany] = useState(JSON.parse(localStorage.getItem('userData')).email);
  const [password, setPassword] = useState('');
  const [timezones, setTimezones] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState();

  const [userData] = useState(JSON.parse(localStorage.getItem('userData')));
// console.log(userData);
  const UpdateProfile = () => {
const FormData = require('form-data');
let data = new FormData();
data.append('firstname', company);
data.append('lastname', ' ');
data.append('password', password);
data.append('timezone',selectedTimezone.id);
data.append('is_recording', ' undefined');
data.append('role', userData.role);
data.append('user_uuid', userData.uuid);

let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: process.env.REACT_APP_API_BASEURL+'/editProfile/'+userData.company_uuid,
  headers: { 
    'Content-Type': 'application/json',   
    'Accept': 'application/json', 
  },
  data : data
};

axios.request(config)
.then((response) => {

  Swal.fire({
    icon: 'success',
    text: 'Your Profile Updated Successfully!',
    showConfirmButton: false,
    timer: 1600 
  });
  // console.log(JSON.stringify(response.data)); 
  localStorage.setItem('first_name', company);
  localStorage.setItem('timezone', selectedTimezone.id); 
})  
.catch((error) => {
  console.log(error);
});

  }

  // console.log("selected timezone: ", selectedTimezone)
  useEffect(() => {
    axios.get(process.env.REACT_APP_API_BASEURL+'/gettimezone/')
      .then(response => {
        const extractedTimezones = response.data.map(timezone => ({
          // label: timezone.gmtzone,
            valtimezone: timezone.gmtzone,
            id: timezone.id
        }));  

        // console.log("get time api data",extractedTimezones)
        JSON.stringify(extractedTimezones);
        // console.log("get timezone api",extractedTimezones)
        setTimezones(extractedTimezones);

        let timezonedata = extractedTimezones.filter(timezone =>Number(timezone.id) == JSON.parse(localStorage.getItem('userData')).timezone)
        setSelectedTimezone(timezonedata && timezonedata[0])
        // {valtimezone: '(GMT-08:00) Pacific Time (US & Canada) Tijuana', id: '5'}
      })
      .catch(error => {
        // console.error('Error fetching timezones:', error);
      });
  }, []);



  // console.log("selected timezones ",selectedTimezone)

  

  // console.log("get timezone api userData", userData);

  const handleCompanyChange = (e) => {
    setCompany(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleTimezoneChange = (e) => {
    // console.log(e.value);
    setSelectedTimezone(e.value);
  };


  // console.log("object of api time zone: " ,timezones)

  // console.log("profile userData",userData)/
  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <Sidebar />
        <div className="container">
          <Nav />
          <div className='updateContainer shadow-sm rounded'>
            <h5>Edit profile</h5>
            <form>
              <div className="form-group p-2">
                <label htmlFor="exampleInputCompany">Company</label>
                <input type="text" className="form-control" id="exampleInputCompany" aria-describedby="emailHelp" placeholder="Enter company" value={company} onChange={handleCompanyChange} />
              </div>
              <div className="form-group p-2">
                <label htmlFor="exampleInputPassword1">Password</label>
                <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" value={password} onChange={handlePasswordChange} />
              </div>
              <div className="form-check p-2">
                <label htmlFor="exampleInputPassword1">Select Your Timezone</label>
                <Dropdown
                  options={timezones}
                  optionLabel="valtimezone"
                  placeholder="Select timezone"
                  value={selectedTimezone}
                  onChange={handleTimezoneChange}
                  className="dropdown "
                />
              </div>
              <div className='form-check p-2 '>
                <button type="button" onClick={UpdateProfile} className='btn btn-primary'>
                  Update Profile
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;

