import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {Chart as ChartJS} from 'chart.js/auto'
import { Bar, Doughnut, Line } from 'react-chartjs-2'
import revenuseData from '../../data/revenueData.json'
import Sidebar from '../Sidebar';
import axios from 'axios';
import { Oval } from 'react-loader-spinner'
import  CsvDownloader  from 'react-csv-downloader'
import * as xlsx from "xlsx";

import Highcharts, { chart } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

 // Expects that Highcharts was loaded in the code.
 

// import 'rsuite/dist/rsuite.min.css';      
// import 'rsuite/Button/styles/index.css'
  import 'rsuite/dist/rsuite-no-reset.min.css';
import { DateRangePicker } from 'rsuite'
import { formatSeconds } from '../Service-Global'
import { Stack }from 'rsuite'
import { subDays,startOfWeek, endOfWeek, addDays,startOfMonth, endOfMonth,addMonths } from 'date-fns';
import moment from 'moment';
import Nav from '../Nav';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

const  PublisherReport =  () =>{
    const navigate = useNavigate()
    const [callData, setCallData] = useState([]);
    const [callExportData, setCallExportData] = useState([]);
    const [ChartData, setChatData] = useState([]);
    const [Campaign, setCampaign] = useState([]);
    const [filterArray, setfilterArray] = useState([]);   
    const [SelectedCampaing, setSelectedCampaign] = useState([]);
    const[ InputField, setInputField] = useState()
    const[selectedDropdown, setSelectedDropdown] = useState({id:0,name: 'Filter-Name-Target'},);
    const [orRule, setOrRule] = useState("");
    const[selectedDropdown1, setSelectedDropdown1] = useState([]);
    const [ summaryData, setSummaryData ] = useState([]);
    const [ summaryExportData, setSummaryExport ] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [showCampaignDropdown, setShowCampaignDropdown] = useState(false);

    // const [showTargetDropdown, setShowTargetDropdown] = useState(false);



    let start_date = moment().format('YYYY-MM-DD 00:00:00')
    let last_date = moment().format('YYYY-MM-DD 23:59:59')
    // console.log(date);
    let filterdata = {
      start_date,last_date
    }
    const[filterDate, setFilterDate] = useState(filterdata);


    const [dropdown,setfiltertypeval] = useState([
      {id:0,name: 'Filter-Name-'+orRule},
      { id:1,name: 'Filter-ID-'+orRule }
    ]);

    const [dropdown1] = useState([
      // { id:2, name: 'Contains' },
      // { id:3, name: 'Does Not contains' },
      // { id:4, name: 'Begins With' },
      // { id:5, name: 'Does Not Begins With' },
      { id:0, name: 'Equals Single Value' },
      { id:1, name: 'Does Not Equals Single Value' }
    ]);
    const [showFlexContainer, setShowFlexContainer] = useState(false);
    const [Display, setDisplay] = useState(false)
    // const[ CampaignDropdown, setCampaignDropdown] = useState();


  
    const handleClick = () => {
      setfilterArray([]) 
      fetchData()
      summary()
      getAllTarget()
      Chart()
      setShowFlexContainer(false); // Show flex container
    // console.log("cleaning filter array",filterArray)
    };

  //   const handleCampaignButtonClick = () => {
  //     setShowCampaignDropdown(true);
  //     setShowFlexContainer(true); 
  //     setShowTargetDropdown(false)
  //     setDisplay(false)
  // };

   const userData = JSON.parse(localStorage.getItem('userData'));

  const Chart = async(filterdata,filterArray) =>{

    const companies = userData?.companydetails?.map((item)=>item.user_uuid)
   let data = JSON.stringify({"filterselected":filterArray,"date":({"startDate":filterdata?.start_date,"endDate":filterdata?.last_date}),"user_uuid":userData.uuid,"role":userData.role,"buyer_uuids":(companies)});

   
   let config = {
     method: 'post',
     maxBodyLength: Infinity,
     url: process.env.REACT_APP_API_BASEURL+'/getChartbyfilter/'+userData.company_uuid+'/2/'+userData.timezone+'/',
     headers: { 
       'Content-Type': 'application/json', 
       'Accept': 'application/json'
     },
     data : data
   };
   
   axios.request(config)
   .then((response) => {
   
      setChatData(response.data)
   
   })
   .catch((error) => {
    //  console.log(error);
   });
  }





  const exportToExcel=async(downloaddata,name)=>{
    try {
   
      let Heading = [["UUID","Name(Filter)", "Incoming","Unique Calls","Connected","Qualified","Converted","Completed","No Answer","No Connect","Blocked","Duplicate","Revenue","CR%","RPC","ACL","TCL"]];
  
        
      const wb = xlsx.utils.book_new();
      const ws = xlsx.utils.aoa_to_sheet([[]]);
      xlsx.utils.sheet_add_aoa(ws, Heading);
  
      //Starting in the second row to avoid overriding and skipping headers
      xlsx.utils.sheet_add_json(ws, downloaddata, { origin: 'A2', skipHeader: true });
  
      xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      xlsx.writeFile(wb, name+'.xlsx');

        // const ws = xlsx.utils.aoa_to_sheet([[]]);
        // let Heading = [['FirstName', 'Last Name', 'Email']];
        // xlsx.utils.sheet_add_json(ws, Heading);
        // xlsx.utils.sheet_add_json(ws, downloaddata, {
        //     dateNF: "dd-mm-yyyy",
        //     cellDates: true,
        //   });
        //   const wb = xlsx.utils.book_new();
        //   // console.log(ws); return          
        //   xlsx.utils.book_append_sheet(wb, ws, "Data");
      
        //   xlsx.writeFile(wb, name+".xlsx");
    } catch (error) {
        console.log(error);
    }
    }

    const exportCalls=async(downloaddata,name)=>{
    try {
   
      let Heading = [["Number",	"Caller ID",	"Target",	"Call Duration",	"Date of Call",	"Time of Call",	"Revenue"]];
  
      //Had to create a new workbook and then add the header
      const wb = xlsx.utils.book_new();
      const ws = xlsx.utils.aoa_to_sheet([[]]);
      xlsx.utils.sheet_add_aoa(ws, Heading);
  
      //Starting in the second row to avoid overriding and skipping headers
      xlsx.utils.sheet_add_json(ws, downloaddata, { origin: 'A2', skipHeader: true });
  
      xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      xlsx.writeFile(wb, name+'.xlsx');

        // const ws = xlsx.utils.aoa_to_sheet([[]]);
        // let Heading = [['FirstName', 'Last Name', 'Email']];
        // xlsx.utils.sheet_add_json(ws, Heading);
        // xlsx.utils.sheet_add_json(ws, downloaddata, {
        //     dateNF: "dd-mm-yyyy",
        //     cellDates: true,
        //   });
        //   const wb = xlsx.utils.book_new();
        //   // console.log(ws); return          
        //   xlsx.utils.book_append_sheet(wb, ws, "Data");
      
        //   xlsx.writeFile(wb, name+".xlsx");
    } catch (error) {
        console.log(error);
    }
    }

    // console.log("value selected in number called",InputField)
 
  // console.log(ChartData)
   const options = {
    title: {
      text: 'Report',
      style: {
        color: '#fff'
      },
    },
    exporting: {
      enabled: true
    },
    subtitle: {
      text:
        'Click and drag in the plot area to zoom in',
        style: {
          color: '#fff'
      },
    },
    credits: {
      enabled: false
    },
    tooltip: {
      shared: true,
    },
    xAxis: {
        minRange: 0,
        gridLineWidth: 0,
        // title: {
        //   text: 'Report'
        
        // },
        categories: ChartData.cattype,

    },
    yAxis: {
      min:0,
      gridLineWidth: 1,
      title: {
        text: 'Number Of Calls',
      //   style: {
      //     color: '#fff'
      // },
      },
      gridLineColor: '#2f3031'
    },
    colors: [
      // 'rgb(58,186,244)',
      "#696DFF"
      ],
    plotOptions: {

      column: {
        colorByPoint: true
      },
      line: {
            dataLabels: {
              useHTML: true,
              enabled: true,
              format: '',
              color: 'white',
          },
          enableMouseTracking: false,
      }
    },
    series: [{
            data: ChartData.chartdata,
            type: 'column',
            name:"Call Count",
            color: '#0066FF',
        },{
            data: ChartData.converted,
            type: 'line',
            name:"Converted",
            color: '#F9B236',
        },
        {
          data: ChartData.connected,
          type: 'line',
          name:"Connected", 
          color: '#20bf55',
      },
      {
        data: ChartData.noanswer,
        type: 'line',
        name:"No Answer",
        color: 'red',
    },
    {
      data: ChartData.noconnect,
      type: 'line',
      name: 'No Connect',
      color: 'red',
  },

    ]
  }



const predefinedRanges = [
  {
    label: 'Today',
    value: [new Date(), new Date()],
    placement: 'left'
  },
  {
    label: 'Yesterday',
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: 'left'
  },
  {
    label: 'This week',
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: 'left'
  },
  {
    label: 'Last 7 days',
    value: [subDays(new Date(), 6), new Date()],
    placement: 'left'
  },
  {
    label: 'Last 30 days',
    value: [subDays(new Date(), 29), new Date()],
    placement: 'left'
  },
  {
    label: 'This month',
    value: [startOfMonth(new Date()), new Date()],
    placement: 'left'
  },
  {
    label: 'Last month',
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left'
  },
  {
    label: 'This year',
    value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
    placement: 'left'
  },
  {
    label: 'Last year',
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
    placement: 'left'
  },
  {
    label: 'All time',
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
    placement: 'left'
  },
  {
    label: 'Last week',
    closeOverlay: false,
    value: value => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
      ];
    },
    appearance: 'default'
  },
  {
    label: 'Next week',
    closeOverlay: false,
    value: value => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
      ];
    },
    appearance: 'default'
  }
];





const getAllTarget =()=>{
  const axios = require('axios');
  const companies = userData?.companydetails?.map((item)=>item.user_uuid) 
  let data = JSON.stringify(companies);
  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: process.env.REACT_APP_API_BASEURL+'/getAlltarget/'+userData.company_uuid+'/4/'+userData.company_uuid, 
    headers: { 
      'Content-Type': 'application/json', 
      'Accept': 'application/json'
    },
    data : data
  };
  
  axios.request(config)
  .then((response) => {
    // console.log(response.data);
    setCampaign(response.data);
  })
  .catch((error) => {
    // console.log(error);
  });

}


const filtercalls =  async(val) => {
  let filterdata
  if(val){
    let start_date = moment(val[0]).format('YYYY-MM-DD 00:00:00')
    let last_date = moment(val[1]).format('YYYY-MM-DD 23:59:59')
    // console.log(date);
    filterdata = {
      start_date,last_date
    }
  }else{
    let start_date = moment().format('YYYY-MM-DD 00:00:00')
    let last_date = moment().format('YYYY-MM-DD 23:59:59')
    // console.log(date);
    filterdata = {
      start_date,last_date
    }
  }


  // console.log("filter date", filterdata);
  fetchData(filterdata,"")
  summary(filterdata,"")
  Chart(filterdata,"");
  getAllTarget(filterdata)
}

const fetchData =  async(filterdata,filterArrayval) => {
  setLoading(true);
  const companies = userData?.companydetails?.map((item)=>item.user_uuid) 
 
    const FormData = require('form-data');
    let data = new FormData();
    data.append('filterselected', JSON.stringify(filterArrayval));
    data.append('user_uuid', userData.uuid);
    data.append('role', userData.role);
    data.append('timezone', userData.timezone);
    data.append('date', JSON.stringify({"startDate":filterdata?.start_date,"endDate":filterdata?.last_date}));
    data.append('buyer_uuids', JSON.stringify(companies));

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_BASEURL+'getcallsdatanew/'+userData.company_uuid,
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
      },
      data : data
    };

 

    try {
        const response = await axios.request(config);
          
        let finaldata = []

        // console.log("fetch all data", response.data[0].data);
        let exportdata = []
        for (let index = 0; index < response.data[0].Response.data.length; index++) {
          const element = response.data[0].Response.data[index];
          element.did = "("+element.did.slice(0,3)+ ') ' +element.did.slice(3,6)+"-"+element.did.slice(6,10)
          element.call_duration =  await formatSeconds(element.call_duration)
          element.ivr_duration =  await formatSeconds(element.ivr_duration)
          element.billable_duration = await formatSeconds(element.billable_duration)
          
          let exportobj = {
            did:element.did,
            routed_to:element.routed_to,
            target:element.target,
            call_duration:element.call_duration,
            date_of_call:element.date_of_call,
            time_of_call:element.time_of_call,                       
            revenue:element.revenue,           
          }
          exportdata.push(exportobj)
        
          // console.log(exportelement); return
          finaldata.push(element);      
        }

        setCallData(finaldata); 
        setCallExportData(exportdata); 

         
      } catch (error) {
        // console.error('response get failed:', error);
      }
      finally {
        // console.log("111");
        setLoading(false); 
    }
}

const summary = async(filterdata,filterArray) =>{ 

  const companies = userData?.companydetails?.map((item)=>item.user_uuid)
  let data = JSON.stringify({
  "filterselected":filterArray,
  "user_uuid": userData?.uuid,
  "role": userData?.role,
  "companies": companies,
  "timezone": userData.timezone,
  "date": {"startDate":filterdata?.start_date,"endDate":filterdata?.last_date},
  "buyer_uuids": companies,
  "groupby": 2,
  "tagselectedvalue": "",
  "user_id": userData.user_id,
  });
  
  let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: process.env.REACT_APP_API_BASEURL +'getcallsdata/'+userData.company_uuid,
  headers: { 
    'Content-Type': 'application/json', 
  },
  data : data
  };
  
  
  
  try {
    const response = await axios.request(config);
    // console.log(response?.data.totalarr);
    let data = response?.data?.data
    setLoading(true); 
    
    let newarr = []
    let exportarr = []
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      element.revenue = "$"+element.revenue
      element.cr_percent = "$"+element.cr_percent
      element.rpc = "$"+element.rpc
      newarr.push(element)
  
      
      let exportobj = {
        uuid:element.uuid,
        filter:element.filter,
        incoming:element.incoming,
        uniquecalls:element.uniquecalls,
        connected:element.connected,
        qualified:element.qualified,
        converted:element.converted,
        completed:element.completed,
        no_answer:element.no_answer,
        no_connect:element.no_connect,
        duplicate:element.duplicate,
        blocked:element.blocked,
        revenue:element.revenue,
        cr_percent:element.cr_percent,
        rpc:element.rpc,
        acl:element.acl,
        tcl:element.tcl,
      }
      exportarr.push(exportobj)
      
    }
    // console.log("summary data:",response.data)
    newarr.push(response?.data.totalarr)
   
    setSummaryData(newarr);
    setSummaryExport(exportarr);
    
     
  } catch (error) {
  }
  finally {
    setLoading(false); 
  }
  
  }

  // console.log("summary data from summary api", summaryData)
const handleButtonClick = (value) => {


  // console.log(value);
  setfiltertypeval([
    {id:0,name: 'Filter-Name-'+orRule},
    { id:1,name: 'Filter-ID-'+orRule }
  ])
  if(value=="Campaign"){
    setSelectedDropdown({id:1,name: 'Filter-ID-Target'})
    setSelectedDropdown1({id:0,name: 'Equals Single Value'})
    setDisplay(false)
  }else{
    setSelectedDropdown({id:1,name: 'Filter-ID-'+value})
    setSelectedDropdown1({id:0,name: 'Equals Single Value'})
  setDisplay(true)
  
    
  }
  console.log(value);
  setOrRule(value);
  setShowFlexContainer(true); 
  
};

// const filterArray = [];
// console.log("Array data",Array)
const handleRuleButton = (andororrule) => {
  // console.log(selectedDropdown);
  // console.log(SelectedTargets);
  // console.log(SelectedTargets); 
  const obj = { 
    filtertype : orRule=="Campaign"?2:6,
    filterlabel : orRule,  
    filteradvanced : selectedDropdown1.id,
    containfiltertext :selectedDropdown1.name,
    containfiltertype : selectedDropdown.id,
    uuid : SelectedCampaing.uuid,
    // name : SelectedTargets.text,
    name : orRule=="Campaign"?SelectedCampaing.text:InputField,
    isorand : andororrule 
  }
console.log("object filter data",obj);
  setfilterArray(oldArray => [...oldArray,obj] );

  // filterArray.push(obj)
 
}



const updatedFilter = () =>{
  // console.log(filterDate);
  let start_date = moment(filterDate[0]).format('YYYY-MM-DD 00:00:00')
  let last_date = moment(filterDate[1]).format('YYYY-MM-DD 23:59:59')
  // console.log(date);
  let filterdata = {
    start_date,last_date
  }

  fetchData(filterdata,filterArray)
  summary(filterdata,filterArray)
  Chart(filterdata,filterArray);
  // console.log("filter array",filterArray);
}


let Clearfilter = (index)=>{
  filterArray.splice(index,1);
  setfilterArray(l => l.filter(item => item.id !== index));
}


useEffect(() => {

  if(userData===null){
    navigate('/')
    return
  }

  let start_date = moment().format('YYYY-MM-DD 00:00:00')
  let last_date = moment().format('YYYY-MM-DD 23:59:59')
  // console.log(date);
  let filterdata = {
    start_date,last_date
  }

    fetchData(filterdata,"");
    summary(filterdata,"");
    Chart(filterdata,"");
    getAllTarget(filterdata);
},[]
)

// console.log("summary data from summary api", summaryData)

  
    return (
      <div className="layout-wrapper layout-content-navbar">
      <Sidebar/>
  <div className="layout-container">
    
  <div className='container'>
  <Nav/>
      

  <div style={{"padding":"10px 0px 0px 25px"}}>
  <div className='d-flex pr-4' >
  <Stack  direction="column" alignItems="flex-start">    
    <DateRangePicker
      ranges={predefinedRanges}
      style={{ width: 400 }}
      format="yyyy/MM/dd HH:mm:ss"
      
      defaultValue={[new Date(new Date().toJSON().slice(0,10).replace(/-/g,'/')+' 00:00:00'), new Date(new Date().toJSON().slice(0,10).replace(/-/g,'/')+' 23:59:59')]}
      onShortcutClick={(shortcut, event) => {
        // console.log(shortcut);
      }}
      onChange={(shortcut, event) => {
        filtercalls(shortcut)
        setFilterDate(shortcut)
        // console.log("filter date",filterDate);
      }}
    />
  </Stack> &nbsp;
  {!showFlexContainer && (
  <div className='ml-2 pt-1'>
  
    <Button className='btn btn-primary mr-2 btn-sm' onClick={() => handleButtonClick("Campaign")}>
      <i className="bi bi-funnel"> CAMPAIGN</i>
    </Button>
    <Button className='btn btn-primary btn-sm' onClick={() => handleButtonClick("Number Called")}>
      <i className="bi bi-funnel"> NUMBER CALLED</i>
    </Button>
  </div>  
)}
          

</div>

      <div className='inlinedata flex  pt-0' >
      {
      filterArray.map((item,i)=>{
        return(
          <div className="mt-2" style={{"padding": "4px"}}>
                  
                  {item.isorand===0 && i!==0 &&
                    <button type="button"  className="btn btn-primary btn-sm" style={{"font-size": "11px", "margin-right": "7px"}}>OR</button>
                  }
                  {item.isorand===1 && i!==0 &&
                    <button type="button"  className="btn btn-primary btn-sm" style={{"font-size": "11px", "margin-right": "7px"}}>AND</button>
                  }
             
                    <button type="button" onClick={()=>Clearfilter(i)} className="btn btn-secondary btn-sm" style={{"font-size": "11px",    "background": "#1c80cb",
                    "padding": "6px 12px",
                    "border": "none"}}>
                      {item.containfiltertype===0 && 
                        <span >Filter - <span >Name -</span> {item.filterlabel} - <small>{item.containfiltertext}</small>&nbsp;&nbsp; <b>{item.name}</b></span>
                      }
                      {item.containfiltertype===1 && 
                        <span>Filter - ID - {item.filterlabel} - <small>{item.containfiltertext}</small>&nbsp;&nbsp; <b>{item.name}</b></span>
                      }
                      &nbsp; <span class="bx bx-x me-2"></span>
                    </button>
                  </div>
        )
      })

      }
      </div>
  </div> 


  {showFlexContainer && (
  <>  
   <div className='flex p-4 pt-0' >
  <Dropdown
    options={dropdown1}
    optionLabel="name"
    placeholder="Contains"
    value={selectedDropdown1}
    onChange={(e) => setSelectedDropdown1(e.target.value)}
    className="dropdown customdropdown"
  />

{Display ? (
<input class="custom-input " id="exampleInputEmail1" onChange={(e) => setInputField(e.target.value)} aria-describedby="emailHelp" placeholder="Enter value"/>
) : (
 
   <Dropdown
    options={Campaign}
    value={SelectedCampaing}
    optionLabel="text"
    onChange={(e) => setSelectedCampaign(e.target.value)}
    checkmark={true}
    placeholder="Select Campaign"
    filter
    className="dropdown customdropdown"
  /> )}
 



       
    <Button className=" btn btn-primary btn-sm p-2 mr-2" onClick={() => handleRuleButton(0)}>
      + OR RULE
    </Button>
    <Button className=" btn btn-primary btn-sm p-2 mr-2" onClick={() => handleRuleButton(1)}>
      + AND RULE
    </Button>
    <Button className=" btn btn-secondary mr-2  ">+Filter</Button>
</div>
      <div className='d-flex p-4 pr-4 pt-0'>
        <Button className='btn btn-primary mr-2 btn-sm' onClick={updatedFilter}>APPLY UPDATED FILTER</Button>
        <Button className='btn btn-outline-danger btn-sm' onClick={handleClick}>CLEAR FILTERS</Button>
      </div>
  </> 
)}


    <div className='dataCard bg-white rounded mx-4 my-0 shadow-sm rounded' >
      {loading ? (
                <div className="d-flex justify-content-center align-items-center pt-5">
                    <Oval height={40} width={40} color="#00BFFF" />
                </div>
            ) : (
  <HighchartsReact
    highcharts={Highcharts}
    options={options}
  />
            )}
    </div>
            
    <>
     <div className='bg-white p-4 summary mx-4 shadow-sm rounded ' >
      <div className='d-flex p-2 justify-content-between'>
        <h5>Summary </h5>
       
       <div className='CsvDownloader'>  
       <Button className='btn btn-primary mr-2 btn-sm' onClick={()=>exportToExcel(summaryExportData,'Summary')}>Export</Button>
        {/* <CsvDownloader datas={summaryExportData} text="Export" filename={`Summery-` + new Date().toDateString()} extension='.csv' className=' btn btn-outline-primary px-4'/> */}
    </div>

   
      </div>
        <div className="card"> 
            <DataTable loading={loading} className='target' emptyMessage="No data found"  value={summaryData}  removableSort  tableStyle
            >
            <Column field="filter" width="100%" header="Campaign" sortable ></Column> {/* Add this line */}
                <Column field="incoming" header="Incoming" sortable ></Column>
                <Column field="uniquecalls" header="Unique Calls" sortable ></Column>
                <Column field="reachedtobuyer" header="IVR input" sortable ></Column>
                <Column field="connected" header="Connected" sortable ></Column>
                <Column field="qualified" header="Qualified" sortable ></Column>
                <Column field="completed" header="Completed" sortable ></Column>
                <Column field="no_answer" header="No Answer" sortable ></Column>
                <Column field="no_connect" header="No Connect" sortable ></Column>
                <Column field="duplicate" header="Duplicate" sortable ></Column>
                <Column field="blocked" header="Blocked" sortable ></Column>
                <Column field="blocked" header="Payout" sortable ></Column>
                <Column field="cr_percent" header="CR%" sortable ></Column>
                <Column field="rpc" header="RPC" sortable ></Column>
                <Column field="acl" header="ACL" sortable ></Column>
                <Column field="tcl" header="TCL" sortable ></Column>

            </DataTable>
        </div>
        </div>

     <div className='bg-white p-4  shadow-sm rounded mx-4 mt-4' >
     <div className='d-flex p-2 justify-content-between'>
        <h5>Call Details</h5>
        
        <div className='CsvDownloader'>  
  {/* <CsvDownloader datas={callData} text="Export" filename={`userdata_` + new Date().toDateString()} extension='.csv' className='btn btn-outline-primary px-4'/> */}
  <Button className='btn btn-primary mr-2 btn-sm' onClick={()=>exportCalls(callExportData,'Calls')}>Export</Button>
  </div>
      </div>
           
      <div className="p-card ">
      
    <DataTable loading={loading}  value={callData}   sortable
                    style={{ width: '100%' }}
                    // filters={filters}
                    paginator
                    rows={50}
                    resizableColumns={true}
                    rowsPerPageOptions={[5,10, 20, 50, 100, 500, 1000]}
                    totalRecords={1000}
                    emptyMessage="No data found"
                    className="custom-datatable calldetails"                        
     >
        <Column field="date_of_call" sortable header="Date Time"></Column>
        <Column field="campaign" sortable header="Campaign"></Column>
        <Column field="did" sortable header="Number Called"></Column>
        <Column field="incoming_number" sortable header="Caller ID"></Column>
        <Column field="target" sortable header="Duplicate"></Column>
        <Column field="call_duration" sortable header="ACTDuration"></Column>
        <Column field="ivr_duration" sortable header="IVRDuration"></Column>
        <Column field="billable_duration" sortable header="Billabe Duration"></Column>
        <Column field="payout" sortable header="Payout"></Column>
        <Column field="" sortable header="Action"></Column>

        {/* Add more columns as needed */}
    </DataTable>
</div>
            </div>
            </>
       
        </div>
        
        </div>
      
        </div>
       
    );
}

export default PublisherReport;