import React, { useEffect } from 'react'
import { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Nav from '../Nav';
import Sidebar from '../Sidebar';
import axios from 'axios';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';

const Campaigns = () => {

    const [Campaigns, setCampaigns] = useState([]);
    const [filters, setFilter] = useState({ global:{value: null, matchMode: FilterMatchMode.CONTAINS}, })
    const userData = JSON.parse(localStorage.getItem('userData'));
    const [loading, setLoading] = useState(false);



   const CampaignsData = async () => {
    try {
        setLoading(true);
      let data = JSON.stringify({
        "role": 2,
        "uuid": "b63bab0-2b6e-4fb7-a453-4049c8157ac5"
      });
  
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://apiv2.dialdigits.com/getCampaign/abc3bab0-2b6e-4fb7-a453-4049c8157ac5/16',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
  
      // Making the request using async/await
      const response = await axios.request(config);
    //   console.log(JSON.stringify(response.data.data));
      setCampaigns(response.data.data);
    } catch (error) {
      console.log(error);
    }
    finally{
        setLoading(false);
    }
  };
  

    // console.log("local storage data", userData)

    useEffect(() => {
       CampaignsData();
          
        }, [])

        const statusBodyTemplate = (rowData) => {
            // const userData = JSON.parse(localStorage.getItem('userData'));
            if (userData && userData.role == 3) {
                return (
                    <div>
                        <div>
                            {rowData.status == 1 ? (
                                <Tag className="bg-success text-white">Pending</Tag>
                            ) : rowData.status == 2 ? (
                                <Tag className="bg-warning text-dark"> Live</Tag>    
                            ) : rowData.status == 3 ? (
                                <Tag className="bg-danger text-white">Rejected</Tag>
                            ) : rowData.status == 4 ? (
                                <Tag className="bg-info text-white">No Number</Tag> // New condition
                            ) : null}
                        </div>
                    </div>
                );
            } else {
               
                return (
                    <div>
                        <div>
                            {rowData.status == 1 ? (
                                <Tag className="bg-success text-white">Live</Tag>
                            ) : rowData.status == 0 ? (
                                <Tag className="bg-warning text-dark">Paused</Tag>
                            )  : null}
                        </div>
                    </div>
                );
            }
        };
        
        
        const RecordBodyTemplate = (rowData) => {
      
            return (
                <div>
                    {rowData.offer_apply_status == 1 ? (
                        <Tag className="bg-success  text-white">Yes</Tag>
                    ) : (   
                        <Tag className="bg-danger text-white">No</Tag>
                    )}
                </div>
            );
        };


   
  return (
    <div className="layout-wrapper layout-content-navbar">
        <Sidebar/>
    <div className="layout-container">
      
    <div className='container'>
    <Nav/>
             
    <div className='bg-white p-4 mt-4 mx-4 shadow-sm rounded' >
          <div className='d-flex p-2 justify-content-between'>
          <h3>Manage Campaigns</h3> 
          {/* <button type="button" class="btn btn-primary btn-sm">+Create Campaign</button> */}
          </div>
          
                   
         <div className='d-flex justify-content-end mb-3'>
          <div className='flex'>        
            <label  className='fw-bold text-black p-2 mt-1 fs-6' > Search: </label>
          <InputText type="text"  style={{ width: '70%',height:'32px' }}  placeholder=''  onInput={(e)=>setFilter(
              {
                  global:{value:e.target.value, matchMode: FilterMatchMode.CONTAINS },
              }
          )}  >
                 
              </InputText>
          </div>
  
         </div>   
                  <DataTable
                    //  virtualScrollerOptions={{ showLoader: true, loading: loading }}
                      loading={loading}
                      value={Campaigns}
                      sortable
                      style={{ width: '100%' }}
                      filters={filters}
                      paginator
                      rows={50}
                      rowsPerPageOptions={[5,10, 20, 50, 100, 200, 1000]}
                      totalRecords={1000}
                      emptyMessage="No data found"
                      // className="custom-datatable"
                      tableStyle={{ minWidth: '50rem' }}
                      className="summury"
                  >
                      <Column field="status" sortable header="Status" body={statusBodyTemplate} />
                      <Column field="name" sortable header="Name" />
                      <Column field="offername" sortable header="Offer Name"  />
                      <Column field="country" sortable header="Country" />
                      <Column field="byhour" sortable header="Hour" />  
                      <Column field="byday" sortable header="Day" />
                      <Column field="bymonth" sortable header="Month" />
                      <Column field="total" sortable header="Total" />
                      <Column field="offer_apply_status" sortable header="Recording" body={RecordBodyTemplate} />
                      <Column field="created_at" sortable header="Created At" />
                      <Column field="" sortable header="Action" />
                     
                  </DataTable>
              {/* )} */}
  
                  </div>
    </div>
    </div>
    </div> 
  )
}

export default Campaigns
