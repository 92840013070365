

// export default Numbers
import React, { useEffect } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Sidebar from '../Sidebar';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';
import { FilterMatchMode } from 'primereact/api';
// import { Oval } from 'react-loader-spinner'
import axios from 'axios';
import Nav from '../Nav';
import { useNavigate } from 'react-router-dom';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';

const Numbers = () => {
      const [filters, setFilter] = useState({ global:{value: null, matchMode: FilterMatchMode.CONTAINS}, })
    const [userData,] = useState(JSON.parse(localStorage.getItem('userData')));
    const [NumbersData, setNumbersData] = useState([{}]);
    const naviagate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [statuses] = useState(['unqualified', 'qualified', 'new', 'negotiation', 'renewal']);
    useEffect(() => {
      // console.log(userData); 
        if(userData===null){
          naviagate("/", { replace: true });
          // naviagate(0)
          return
        }
      }, [])

    const GetallNumbers = async () =>{  
    
     
      try {
        setLoading(true);
        let data = JSON.stringify({
          "number": "",
          "campaign": "",
          "labels": ""  ,
          "role": userData.role,
          "useruuid": userData.uuid
        });
        
        let config = {

          url: process.env.REACT_APP_API_BASEURL+'/getnumbersdata/'+userData.company_uuid,
          method: 'post',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        let response =   await axios.request(config)
        // .then((response) => {
        //   console.log(JSON.stringify(response.data));    
          const responseData = response.data.data; 
          setNumbersData(responseData);
      }
      catch (error) {
        console.error('response get failed:', error);
       }
      finally {
        setLoading(false);
    }
    }
    const statusBodyTemplate = (rowData) => {
      
      return (
          <div>
              {rowData.status == 1 ? (
                  <Tag className="bg-success text-white">Enable</Tag>
              ) : (
                  <Tag className="bg-danger text-white">Disable</Tag>
              )}
          </div>
      );
  };
  
 
    useEffect(() =>{
        GetallNumbers();
    },[])
    
    

  return (
    <div className="layout-wrapper layout-content-navbar">
        <Sidebar/>
    <div className="layout-container">
      
    <div className='container'>
    <Nav/>
             
        <div className='bg-white p-4 mt-4 mx-4 shadow-sm rounded' >
          
        <h3>Manage Numbers</h3> 

       <div className='d-flex justify-content-end mb-3'>
        <div className='flex'>        
          <label  className='fw-bold text-black p-2 mt-1 fs-6' > Search: </label>
        <InputText type="text"  style={{ width: '70%',height:'32px' }}  placeholder=''  onInput={(e)=>setFilter(
            {
                global:{value:e.target.value, matchMode: FilterMatchMode.CONTAINS },
            }
        )}  >
               
            </InputText>
        </div>
       </div>
       

               <DataTable       
                    loading={loading}
                    value={NumbersData}
                    sortable
                    style={{ width: '100%' }}
                    filters={filters}
                    paginator
                    rows={50}
                    rowsPerPageOptions={[5,10, 20, 50, 100, 200, 1000]}
                    totalRecords={1000}
                    emptyMessage="No data found"
                    tableStyle={{ minWidth: '50rem' }}
                    className="summury"
                >
                    <Column field="number" sortable header="Number" />
                    <Column field="campaign" sortable header="Campaign" />
                    <Column field="publisher" sortable header="Publisher" />
                    {/* <Column field="status" sortable header="Status" /> */}
                    {!loading && (
                    <Column field="status" header="Status" sortable showFilterMenu={false} filterMenuStyle={{ width: '14rem' }}
                    style={{ minWidth: '12rem' }} body={statusBodyTemplate} filter />)}
                    <Column field="assosciated_labels" sortable header="Associated Label" /> 
                    <Column field="created_at" sortable header="Allocated" />
               
                   
                    {/* Add more columns as needed */}
                </DataTable>
            {/* )} */}

                </div>
                </div>
  </div>
  </div>
  )
}

export default Numbers
