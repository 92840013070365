import React, { useEffect } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Sidebar from '../Sidebar';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';
import { FilterMatchMode } from 'primereact/api';
// import { Oval } from 'react-loader-spinner'
import axios from 'axios';
import Nav from '../Nav';
import { useNavigate } from 'react-router-dom';

const Target = () => {
    const [callData, setCallData] = useState([{}]);
    const [filters, setFilter] = useState({ global:{value: null, matchMode: FilterMatchMode.CONTAINS}, })
    const [loading, setLoading] = useState(false)
    const [userData,] = useState(JSON.parse(localStorage.getItem('userData')));
    const naviagate = useNavigate()
    useEffect(() => {
      // console.log(userData); 
        if(userData===null){
          naviagate("/", { replace: true });
          // naviagate(0)
          return
        }
      }, [])

    const fetchData = async () =>{       
       
try {      
    const userData = JSON.parse(localStorage.getItem('userData'));
    const companies = userData?.companydetails?.map((item)=>item.user_uuid)
    // console.log(companies);
     // Set loading state to true before fetching data
     setLoading(true);
    // console.log(userData);
      let data = JSON.stringify({
        "user_uuid": userData?.uuid,
        "role": userData?.role,
        "companies": companies
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_API_BASEURL+'gettargets/abc3bab0-2b6e-4fb7-a453-4049c8157ac5',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };

      let response = await axios.request(config);

    // console.log('response:', response.data.data); return
  
    const responseData = response.data.data; 
    
    setCallData(responseData);
  

  } catch (error) {
    console.error('response get failed:', error);
  } finally {
    // Set loading state to false after fetching data
    setLoading(false);
}
  
    }

    useEffect(() =>{
        fetchData();
    },[])
    
    

  return (
    <div className="layout-wrapper layout-content-navbar">
        <Sidebar/>
    <div className="layout-container">
      
    <div className='container'>
    <Nav/>
             
        <div className='bg-white p-4 mt-4 mx-4 shadow-sm rounded' >
          
        <h3>Manage Targets</h3> 

       <div className='d-flex justify-content-end mb-3'>
        <div className='flex'>        
          <label  className='fw-bold text-black p-2 mt-1 fs-6' > Search: </label>
        <InputText type="text"  style={{ width: '70%',height:'32px' }}  placeholder=''  onInput={(e)=>setFilter(
            {
                global:{value:e.target.value, matchMode: FilterMatchMode.CONTAINS },
            }
        )}  >
               
            </InputText>
        </div>

       </div>   
                <DataTable
                  //  virtualScrollerOptions={{ showLoader: true, loading: loading }}
                    loading={loading}
                    value={callData}
                    sortable
                    style={{ width: '100%' }}
                    filters={filters}
                    paginator
                    rows={50}
                    rowsPerPageOptions={[5,10, 20, 50, 100, 200, 1000]}
                    totalRecords={1000}
                    emptyMessage="No data found"
                    // className="custom-datatable"
                    tableStyle={{ minWidth: '50rem' }}
                    className="summury"
                >
                    <Column field="name" sortable header="Name" />
                    <Column field="buyers" sortable header="Buyer" />
                    <Column field="valtype" sortable header="Type" body={(rowData) => rowData.valtype ? 'Number' : ''} />
                    <Column field="typeval" sortable header="Type Value" />
                    <Column field="hour" sortable header="Hour" />
                    <Column field="day" sortable header="Day" />
                    <Column field="month" sortable header="Month" />
                    <Column field="total" sortable header="Total" />
                    <Column field="created_at" sortable header="Created Date" />
                    {/* Add more columns as needed */}
                </DataTable>
            {/* )} */}

                </div>
                </div>
  </div>
  </div>
  )
}

export default Target
