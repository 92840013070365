import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Logo from '../logo/logo.png';
import Loginbg from '../logo/loginbg.webp';

const Login = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState(''); 
  const [error, setError] = useState('');
  const [userData] = useState(JSON.parse(localStorage.getItem('userData'))); 
  const navigate = useNavigate()
 
      useEffect(() => {
        if(userData===null){
          navigate("/", { replace: true });
          return  
        }
      }, [])

      // useEffect(() => {
      //   if (userData !== null) {
      //     navigate("/dashboard", { replace: true });
      //   }
      // }, [userData, navigate]);
      

    const handleSubmit = async (e) => {
      e.preventDefault(); 
      let data = JSON.stringify({ username,password});
      
  let config = {
    method: 'POST',
    maxBodyLength: Infinity,
    url: process.env.REACT_APP_API_BASEURL +'login',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };

  try {
    const response = await axios.request(config);
  
    if(response.data.role===3 || response.data.role ===4)
    {
      props.iscallapp(response.data)
      navigate("/dashboard")
    } 
    else
    {
      setError('Invalid User Name or Password');
      navigate(false)
      
    }

    if (response.data.logged_in === "false") {
      setError('Invalid User Name or Password');
      navigate(false)
    }
    else
    {      
    localStorage.setItem('userData', JSON.stringify(response.data));
    }
  } catch (error) {
    console.error('Login failed:', error);
      }

  if(username === "" && password ==="")
  {
    setError('Please enter User Name and Password');
  }

  };


  return (
      <>
      <div class="authentication-wrapper authentication-basic">
        <div class="authentication-inner d-flex">
        
          {/* <!-- Register --> */}
         <div className='loginimg'>
          <img src={Loginbg} alt="no image found" className='Loginbg'/>
          </div>


            <div class="loginCard " >
              {/* <!-- Logo --> */}
              <div class="app-brand justify-content-center  ">
              <div className=' LoginLogo d-flex justify-content-center  align-items-center '>
                <img src={Logo} alt="no image found"  height="70px"  width="350px"/>
              </div>
              </div>
              {/* <!-- /Logo --> */}
              <h4 class="mb-2">Welcome to DialDigits! 👋</h4>
              <p class="mb-4">Please sign-in to your account and start the adventure</p>

              <form id="formAuthentication" class="mb-3"  method="POST">
                <div class="mb-3">
                  <label for="email" class="form-label">Email or Username</label>
                  <input
                    type="text"
                    class="form-control"
                    id="email"
                    name="email-username"
                    placeholder="Enter your email or username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    autofocus
                  />
                </div>
                <div class="mb-3 form-password-toggle">
                  <div class="d-flex justify-content-between">
                    <label class="form-label" for="password">Password</label>
                  </div>
                  <div class="input-group input-group-merge">
                    <input
                      type="password"
                      id="password"
                      class="form-control"
                      name="password"
                      placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                      aria-describedby="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span class="input-group-text cursor-pointer"><i class="bx bx-hide"></i></span>
                  </div>
                  {error && <span className="text-danger">{error}</span>}
                </div>
                <div class="mb-3">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="remember-me" />
                    <label class="form-check-label" for="remember-me"> Remember Me </label>
                  </div>
                </div>
                <div class="mb-3">
                  <button class="btn btn-primary d-grid w-100"  onClick={handleSubmit} type="submit">Sign in</button>
                </div>
              </form>

              
            </div>
          </div>
          {/* <!-- /Register --> */}
         
       
      </div>
    
    </>
  );
};

export default Login;