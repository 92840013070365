import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar";
import Nav from "../Nav";

const Dashboard = () => {

   const naviagate = useNavigate()
   const userData = JSON.parse(localStorage.getItem('userData'));
   
   useEffect(() => {
    if(userData===null){
        naviagate('/')
        return
      }
   }, [])




	return (
	
    <div className="layout-wrapper layout-content-navbar">
        <Sidebar/>
    <div className="layout-container">
      
    <div className='container'>
      <Nav/>
             
        <div >

            <div className="col-md-11 mb-4 p-0 mx-4 my-4 order-0">
                <div className="card">
                <div class="d-flex align-items-end ">
                        <div class="col-sm-7">
                          <div class="card-body">
                            <h5 class="card-title text-primary">Congratulations {userData?.first_name}! 🎉</h5>
                            <p class="mb-4">
                            Welcome to your insightful analytical dashboard, Click View Report to unlocking data insights.
                            </p>

                            <Link to="/report" href="javascript:;" class="btn btn-sm btn-outline-primary">View Report</Link>
                          </div>
                        </div>
                        <div class="card-body col-sm-5 text-center text-sm-left">
                          <div class="pb-0 px-0 px-md-4">
                            <img
                              src="../assets/img/illustrations/man-with-laptop-light.png"
                              height="140"
                              alt="View Badge User"
                              data-app-dark-img="illustrations/man-with-laptop-dark.png"
                              data-app-light-img="illustrations/man-with-laptop-light.png"
                            />
                          </div>
                        </div>
                      </div>
                </div>
            </div>    
            {/* </div> */}
            {/* </div>  */}
            </div>
        </div>
    </div>
</div>
		
	);
};

export default Dashboard;
