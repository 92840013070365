import React, { useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react"; 
import Swal from 'sweetalert2'; // Import SweetAlert

const Nav = () => {
    const navigate = useNavigate()
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [userData,] = useState(JSON.parse(localStorage.getItem('userData')));
    
    useEffect(() => {
    // console.log(userData); 
      if(userData===null){
        navigate("/", { replace: true });
        return
      }
    }, [])
    
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
      };
    

    const logout = () => {
        // Display SweetAlert confirmation dialog
        Swal.fire({
            title: 'Logout',
            text: 'Are you sure you want to logout?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, logout'
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.clear();
                navigate("/", { replace: true });
            }
        });
    };


      function openFullscreen() {
       const elem = document.documentElement;
       if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
           // Enter full screen mode
           if (elem.requestFullscreen) {
               elem.requestFullscreen();
           } else if (elem.mozRequestFullScreen) { /* Firefox */
               elem.mozRequestFullScreen();
           } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
               elem.webkitRequestFullscreen();
           } else if (elem.msRequestFullscreen) { /* IE/Edge */
               elem.msRequestFullscreen();
           }
       } else {
           // Exit full screen mode
           if (document.exitFullscreen) {
               document.exitFullscreen();
           } else if (document.mozCancelFullScreen) { /* Firefox */
               document.mozCancelFullScreen();
           } else if (document.webkitExitFullscreen) { /* Chrome, Safari & Opera */
               document.webkitExitFullscreen();
           } else if (document.msExitFullscreen) { /* IE/Edge */
               document.msExitFullscreen();
           }
       }
     }

     const showhidemenu = (type) => {
        let _userAccount = document.getElementById('htmltag')
          _userAccount.classList.add('layout-menu-expanded')         
      };

  return (
    <>
         <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
                <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none" >
                    <a className="nav-item nav-link px-0 me-xl-4" onClick={()=>showhidemenu(0)}>
                        <i className="bx bx-menu bx-sm"></i>
                    </a>
                </div>
                <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                    <div className="navbar-nav align-items-center">
                        <div className="nav-item d-flex align-items-center">
                        <i class="bx bx-fullscreen fs-4 lh-0" onClick={openFullscreen}></i>
                        </div>
                    </div>
                    <ul className="navbar-nav flex-row align-items-center ms-auto">
                        <li className="nav-item navbar-dropdown dropdown-user dropdown">
                            <a className="nav-link dropdown-toggle hide-arrow" onClick={toggleDropdown} href="javascript:void(0);" data-bs-toggle="dropdown">
                                <div className="avatar avatar-online">
                                    <img src="../assets/img/avatars/1.png" alt className="w-px-40 h-auto rounded-circle" />
                                </div>
                            </a>
                            <ul className={`dropdown-menu dropdown-menu-start ${isDropdownOpen ? 'show' : ''}`}>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar avatar-online">
                                                    <img src="../assets/img/avatars/1.png" alt="" className="w-px-40 h-auto rounded-circle" />
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <span className="fw-semibold d-block">{userData?.first_name}</span>
                                                <small className="text-muted">Admin</small>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                              
                              
                               
                                
                                <li>
                                    <div className="dropdown-divider"></div>
                                </li>
                                <li>
                      <a class="dropdown-item" href="#">
                        <i class="bx bx-user me-2"></i>
                        <Link to="/profile" class="align-middle">My Profile</Link>
                      </a>
                    </li>
                                <li onClick={logout}>
                                    <a className="dropdown-item" >
                                        <i className="bx bx-power-off me-2"></i>
                                        <span className="align-middle">Logout</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul> 
                </div>
            </nav>
    </>
  )
}

export default Nav